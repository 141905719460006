@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

.marketing-site-content-section {
  display: flex;
  flex-wrap: wrap;

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .marketing-site-content-section-img {
    align-self: center;
    flex: 1 0 100%;

    img {
      width: 100%;
    }

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
    }
  }

  .marketing-site-content-section-block {
    padding: 1rem;
    flex: 1 0 100%;
    align-self: stretch;
    background: $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
      padding: 2rem;
    }
  }
}

.marketing-site-content-section-block-header {
  font-size: 24px;
}

.marketing-site-content-section-block-subheader {
  font-size: 13px;
}




.social-links {
  /*background: #1d92cf;*/
  background: rgb(10, 115, 186);

  /*padding: 2rem 0 1rem;*/
}

.social-links .facebook {
  /*background: #1d92cf;*/
  background: 	#3b5998;
  padding-top: 1rem;
}

.social-links .twitter {
  background: 	#1da1f2;
  padding-top: 1rem;
}

.social-links .youtube {
  background: 	#cd201f;
  padding-top: 1rem;
}

.social-links .email {
  background: 	#999999;
  padding-top: 1rem;
}


.social-links a {
  color: white;
  font-size: 1.2rem;
}

.social-links i.fa {
  font-size: 1.4rem;
  padding-right: 0.8rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 39.9375em) {
  /*.social-links {
    padding: 2rem 0;
  }*/
  .social-links i.fa {
    font-size: 2rem;
    display: block;
    padding-right: 0;
    /*padding-bottom: 0.5rem;*/
  }
  .social-links a {
    font-weight: bold;
  }
  /*.social-links .columns {
    margin: 1rem 0;
  }*/
  .social-links .mobile-stack {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.docs-nav.menu {
	margin-top: 0.5rem;
	background: #fafafa;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.docs-nav.menu li small {
	color: #999;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 60%;
	display: block;
	margin-top: 5px;
}

.docs-nav.menu a {
	font-size: 0.85rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.docs-nav.menu a:hover {
	background: rgba(0, 0, 0, 0.05);
}

.docs-nav.menu .current a {
	background: rgba(0, 0, 0, 0.05);
}

.docs-nav.menu .label {
	background-color: #ddd;
	color: #777;
	margin-left: 5px;
	padding: 0.22222rem 0.44444rem 0.22222rem;
	font-size: 0.61111rem;
}

.docs-nav-title {
	text-transform: uppercase;
	font-size: 0.75rem;
	color: #999;
	font-weight: bold;
	line-height: 1;
	padding-left: 0.9rem;
	margin-bottom: 0.5rem;
	border-top: 1px solid #e9e9e9;
	margin-top: 1rem;
	padding-top: 1.5rem;
}

.docs-page-title {
	margin-top: 0.5rem;
	display: inline-block;
	vertical-align: middle;
}
